@import 'vars';
.sidenav {
  height: 100vh; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.2s; /* 0.5 second transition effect to slide in the sidenav */
  -webkit-transition: 0.2s;
}

/* The navigation menu links */

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
}

.show-navbar {
  padding-top: 0 !important;
  background-color: rgba(0, 0, 0, 0.6);

  .warp-navbar {
    width: 80%;
    height: 100%;
    background-color: #ffffff;
    padding-bottom: 82px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.header-navbar {
  display: flex;
  background-color: teal;
  min-height: 75px;

  div:first-child {
    margin: 20px 10px;
    width: 15%;

    img {
      border-radius: 50%;
    }
  }

  div:last-child {
    color: white;
    padding-top: 15px;
    width: 60%;

    span:first-child {
      font-weight: bold;
      font-size: 16px;
    }

    span:last-child {
      font-size: 12px;
      line-height: 1;
      display: inline-block;
    }

    &:after {
      font-family: 'Font Awesome 5 Free', serif;
      font-weight: 900;
      content: '\f105';
      position: absolute;
      top: 20px;
      right: 23%;
    }
  }
}

.content-navbar {
  .ul-root-navbar {
    .li-root-navbar {
      line-height: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;
      //border-bottom: 1px solid gray;

      .sub-menu {
        display: none;
        background: rgba(32, 171, 173, 0.2);
        margin-right: 15px;
        border-radius: 5px;
      }
    }
    .active {
      color: #20abad !important;
    }

    .sub-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
      padding: 10px 0;

      img {
        vertical-align: middle;
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
      }
    }

    .level-0 {
      font-size: 20px !important;
    }

    .level-2 {
      font-weight: 500 !important;
    }

    .level-3 {
      font-weight: normal !important;
    }
  }

  ul {
    padding-left: 0;

    li {
      line-height: 2rem;
      font-weight: bold;
    }

    .li-sub-navbar {
      padding-left: 1rem;
    }
  }

  li {
    list-style-type: none;
  }
}

.prescription-navbar {
  padding-bottom: 1rem;
  border-bottom: 1px solid gray;
}

.support-navbar {
  padding: 1rem 2rem;
}

/* global */

.display-flex {
  display: flex !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.fwbold {
  font-weight: bold;
}

.pl-3 {
  padding-left: 1.5rem;
}

.fz-18 {
  font-size: 18px;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-13 {
  font-size: 13px;
}

.text-bl {
  color: #198c8c;
}

.text-blu {
  color: #1186b7;
}

.text-yellow {
  color: #faa723;
}

.text-black {
  color: #000;
}

.text-origin {
  color: #f15a2b;
}

.text-uppercase {
  text-transform: uppercase;
}

.r-30 {
  right: 30% !important;
}

.btn-bl {
  color: #fff;
  background-color: #198c8c;
  border-color: #198c8c;
  border-radius: 20px;

  &:hover {
    color: white;
  }
}

.root-screen {
  background-color: #e9ecef;
  position: absolute;
  width: 100%;
  min-height: calc(100% - 100px);
}

.text-load-more {
  text-align: center;
  color: #198c8c;
  font-size: 18px;

  &:before {
    font-family: 'Font Awesome 5 Free', serif;
    content: '\F107';
    font-weight: 900;
    padding-right: 0.5rem;
  }
}

.width-100 {
  width: 100% !important;
}

.show-all {
  position: absolute;
  right: 5px;
  margin-top: -30px;
  color: #198c8c;
  text-decoration-line: underline;
}

.btn-orr {
  background-color: #f15a2b;
  border: 1px solid #f15a2b;
  color: white;
}

.text-dr {
  color: #0a7379;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}

.owl-carousel {
  touch-action: none;
  //-ms-touch-action: none;
}

.tooltip-master {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
}

.tooltip-master .tooltip-text {
  visibility: hidden;
  min-width: 280px;
  background-color: wheat;
  color: black;
  font-weight: 400;
  font-size: 0.75rem;
  border-radius: 6px;
  padding: 5px 0 5px 10px;
  border: 1px gray solid;
  line-height: 1.5rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -80px;
}

.tooltip-master:hover .tooltip-text {
  visibility: visible;
}

.custom-control-label:before {
  background-color: #dee2e6;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #198c8c !important;
  border-color: #198c8c !important;
}

.btn-outline-bl {
  background-color: white;
  border: 1px solid $base-color;
  color: $base-color !important;
  font-weight: bold;

  &:active {
    background-color: $base-color;
    border: 1px solid $base-color;
    color: white !important;
  }
}

.nav-left-header {
  background: #20abad;
  padding: 15px;

  &--flex {
    display: flex;
    align-items: center;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__login {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #20abad;
      background: #ffffff;
      border-radius: 20px;
      border: none;
      padding: 8px 20px;
      width: calc(50% - 10px);

      &:focus {
        outline: none;
      }
    }

    &__register {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 8px 20px;
      background: transparent;
      width: calc(50% - 10px);

      &:focus {
        outline: none;
      }
    }
  }

  &__avatar {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 4px solid #ffffff;
    }
  }

  &__user {
    flex: 1;
    padding-left: 10px;

    &__name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      p {
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        /* identical to box height */

        color: #ffffff;
      }
    }

    &__point {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 0 10px;
        border-right: 1px solid #ffffff;
        margin-right: 10px;
      }

      P:last-child {
        margin-right: 0;
        border: none;
      }
    }
  }
}

.nav-left-barcode {
  padding: 10px 0;
  margin: 0 15px;

  img {
    width: 100%;
    max-width: 100%;
    height: 50px;
  }
}

.nav-left-btn-pay {
  background: rgba(32, 171, 173, 0.3);
  margin: 0 15px;
  border-radius: 5px;

  p {
    padding: 6px 20px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #3f3b3b;
  }
}

.nav-left-support {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;

  a {
    width: calc(50% - 7.5px);
  }

  button {
    border: 1px solid #008080;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    background: none;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }

  p {
    flex: 1;
    padding-left: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #008080;
  }

  strong {
    font-weight: bold;
  }
}

.nav-left-prescription {
  background: #008080;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 0 15px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 20px;
  }
}

.switch-box-lang {
  input[type='checkbox'] {
    display: none;
  }

  #labelCheckboxLang {
    position: relative;
    display: block;
    width: 56px;
    height: 24px;
    background: #008080;
    border-radius: 24px;
    cursor: pointer;
    //transform: scale(0.4);
  }

  #langCursor {
    width: 26px;
    height: 26px;
    background-size: auto 100%;
    background-image: url('../../assets/img/vn.png');
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    top: -1px;
    left: -4px;
    border-radius: 40px;
    transition:
      0.4s ease left,
      0.4s ease background-position;
    z-index: 2;
    border: 0.5px solid #ffffff;
  }

  #vi,
  #jp {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    z-index: 1;
    margin-left: 8px;
  }

  #vi {
    margin-left: 32px;
  }

  #lang-checkbox:checked + #labelCheckboxLang #langCursor {
    left: 30px;
    background-image: url('../../assets/img/ja.png');
  }
}

/* end global */
