@import 'vars';

.alert-overlay {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-close-btn {
    padding: 5px 10px 5px 10px;
    background-color: white;
    border: 1px solid #111111;
    border-radius: 20px;
    min-width: 100px;
    &:focus,
    &:hover {
      outline: 0;
    }
  }
  .alert-close-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .alert-body {
    background-color: white;
    width: 90%;
    min-height: 200px;
    margin-top: -5em;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    position: relative;

    .alert-close {
      position: absolute;
      top: -5px;
      right: 15px;
      display: block;
      font-size: 30px;
      cursor: pointer;
    }

    .alert-logo {
      img {
        width: 128px;
        height: 128px;
      }
    }
    .alert-label {
      color: $base-color;
      font-size: 20px;
      font-weight: bolder;
    }
    .alert-content {
      padding-left: 20px;
      padding-right: 20px;
    }
    .alert-content-sign {
      margin-top: 10px;
      a {
        color: $base-color;
        text-decoration: none;
        font-weight: bolder;
      }
    }
  }

  .alert-footer {
    margin-top: 20px;
  }
}
.alert-overlay.warning {
  .alert-label {
    color: #df3c3c;
  }
  .alert-close-btn {
    background-color: #df3c3c;
    border: 0;
    color: white;
  }
}

.btn-alert-login {
  button {
    width: 80%;
    border-radius: 25px;
  }
}

.social {
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  //float: left;
  font-size: 20px;
  color: white;

  &.social-facebook {
    background-color: #4d6baa;
    margin-right: 1rem;
  }

  &.social-google {
    background-color: #ea6060;
  }
}

.alert-body-image {
  width: 97%;
  margin-top: -5em;
  text-align: center;
  border-radius: 20px;
  position: relative;

  .alert-close {
    position: absolute;
    top: -5px;
    right: 3px;
    display: block;
    cursor: pointer;
  }

  .alert-content {
    img {
      width: 100%;
      height: auto;
    }
  }
}
