$base-color: #008080;
$main-color: #20ABAD;
$omi-color: #eb592d;
$common-padding: 10px;
$white-color: #ffffff;
$text-color: #3f3b3b;
$border-color: #767474;
$light-border-color: #e0e0e0;
$blackColor: #000000;
$baseColorLight: #20abad;
$orangeColor: #f0592d;
$orangeColor2: #fb800f;
$baseColorStrong: #008080;