@import 'vars';
@import 'navbar';

//reset css
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

button {
  touch-action: manipulation;
}
.main-entry {
  .home-divider {
    width: 100%;
    height: 8px;
    background-color: #f2f2f2;
  }
}
.hidden {
  display: none;
}

.form-group .error {
  color: red;
  font-size: 14px;
}

.input-error {
  border: 1px solid red !important;
}
.input-error:hover {
  border: 1px solid red;
}

.wrap-input {
  border-color: #707070;
  border-radius: 10px;
}

img.lazy {
  height: 1px !important;
  width: 1px !important;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
}
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.header {
  padding-bottom: 0.75em;
  padding-left: $common-padding;
  padding-right: $common-padding;
  position: fixed;
  height: 50px;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.03);
}
.header-wrapper {
  width: 100%;
  display: flex;
  margin-top: 10px;

  .menu-block {
    margin-right: auto;
    margin-top: 5px;
  }
  .ic-menu-home {
    width: 20px;
    height: 16px;
  }
  .logo-block {
    margin-left: 50px;
  }
  .logo-block img {
    height: 32px;
  }

  .cart-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;

    .nav-item_cart-number {
      position: absolute;
      top: 8px;
      right: 6px;
      font-size: 10px;
      background-color: red;
      color: #ffffff;
      padding: 4px;
      border-radius: 50%;
    }
  }

  .search-icon {
    display: inline-block;
    img {
      width: 18px;
    }
  }
  .quick-search {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .noti-icon-home {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .cart-icon {
    position: relative;
    display: inline-block;
    margin-left: 35px;
    img {
      position: absolute;
      right: -4px;
      top: -20px;
    }
    .cart-badge {
      position: absolute;
      font-size: 11px;
      right: -4px;
      top: -20px;
      background-color: #f15a2b;
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
    }
  }
}
.search-wrapper {
  padding-left: $common-padding;
  padding-right: $common-padding;

  .search-input-wrapper {
    display: flex;
    width: 100%;
    margin-top: 0.75em;
    border: 0.1em solid #707070;
    height: 2em;
    border-radius: 0.75em;
  }
  .search-input {
    outline: 0;
    border: 0;
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;
    font-size: 14px;
  }

  .search-icon {
    display: inline-block;
    width: 32px;
  }
}

// ContactInfor - Start

.contact-infor {
  margin-top: 0.75em;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  padding-left: $common-padding;
  padding-right: $common-padding;
}

.contact-infor__address {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding-right: 20px;
}

.contact-infor__address__icon--left {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 5px;
}

.contact-infor__address__icon--right {
  width: 15px;
  height: 10px;
  object-fit: contain;
}

.contact-infor__address__text {
  color: #3f3b3b;
  font-size: 12px;
  font-weight: 400;
}

.contact-infor__phone {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-infor__phone__icon {
  width: 13px;
  height: 16px;
  object-fit: contain;
  margin-right: 5px;
}

.contact-infor__phone__text {
  color: #008080;
  font-size: 14px;
  font-weight: 700;
}

// ContactInfor - End

// BlockGetAppMobile - Start

.block-get-app-mobile {
  display: flex;
  height: 34px;
  background-color: #20abad;
  justify-content: center;
  align-items: center;
}

.block-get-app-mobile__text {
  color: white;
  font-size: 12px;
}

// BlockGetAppMobile - End

.notification {
  background-color: $base-color;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px $common-padding 5px $common-padding;
  text-align: center;

  .content {
    color: white;
    margin-left: 5px;
  }
}

.block-label {
  color: $base-color;
  border-left: 2px solid $base-color;
  font-size: 18px;
  padding-left: calc($common-padding / 2);
}

.see-all-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.see-all {
  height: 42px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
  color: $base-color;
}

.mr-5 {
  margin-right: 5px;
}

.breadcum {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: $common-padding * 1.5;
  padding-right: $common-padding * 1.5;
  background-color: #e9ecef;
}

.omicare-table {
  width: 100%;
}

.products-scroller {
  overflow-x: scroll;
  white-space: nowrap;
  margin-top: 10px;
}

.group-checkbox {
  margin-bottom: 0;
}

.title-bar {
  z-index: 10;
  display: flex;
  position: fixed;
  background-color: #1f8f8f;
  color: #fff;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    display: block;
    margin-top: 30px;
  }
  width: 100%;
  .title-bar-back {
    position: absolute;
    left: 10px;
    img {
      width: 10px;
    }
  }

  .title-bar-content {
    font-size: 18px;
  }
}
.loading {
  color: $base-color;
  text-align: center;
}
/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border: 1px solid #1f8f8f;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #1f8f8f;
  }
}

.form-wrapper {
  padding-left: $common-padding;
  padding-right: $common-padding;
  padding-top: $common-padding;
}

.btn-primary {
  background-color: $base-color;
  border-color: $base-color;
}

.adjust-quantity-input {
  height: 41px;
  width: 60px;
  border: 1px solid #ccc;
  outline: 0;
  text-align: center;
  border-radius: 0;
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 5px;
}

.adjust-quantity {
  min-width: 40px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-weight: bolder;
  &:hover {
    outline: 0;
  }
  &.minus {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  &.plus {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.btn-buy-now {
  font-weight: bolder;
  padding: 10px;
  width: 100%;
  color: white;
  border: 1px solid #ee5124;
  border-radius: 10px;
  background-color: $omi-color;
  &:hover {
    outline: 0;
  }
}

.app-breadcrumb {
  padding: 5px;
  background-color: #e9ecef;
}
.breadcrumb-list {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  li {
    display: inline-block;
    margin-left: 5px;
    a {
      color: #111;
    }
  }
}

.splitter {
  height: 5px;
  width: 100%;
  background-color: #eeeeee;
  margin-top: 8px;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin: 15px 0;
  .page-item.active .page-link {
    background-color: $base-color !important;
    border-color: $base-color !important;
    color: #fff;
  }
  .page-item {
    border-radius: 50%;
    a {
      color: $base-color;
    }
  }
}

.tag {
  border: 1px solid #ccc;
  border-radius: 13px;
  display: inline-block;
  padding: 0 6px;
  height: 26px;
  line-height: 23px;
  margin-right: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  max-width: 95%;
  &.active {
    background-color: #ccc;
  }
}

.back-top {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAkCAMAAAD1hTl/AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABCUExURUdwTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHPTtPsAAAAVdFJOUwDz6SIF3pj6MsFb0AsSa6wZS4ZAeKh/0PMAAAEhSURBVDjLnVVZEoMgDEUWQVwq1tz/qrUEKzpRQvPhjKMPyFuCEPcVFvFHuQZ8PUpJAOtqUaOBrcxchxo6iNXpKtgLUrU1qBZ+NfFRUwRYG59vLsrjPh7RUvFQ77gLrPtZ+4ErWOJCI58vBmo22a/pZS0L1qNew89h3woFlEbBzHimp1EcwXLyFgYtK659cnCZliRYIEwNS0mwq50U0uLvc0kvG4ij04JRLR/0EoLp+xwR35KP+vHJBC1DMMqoV1qWp64Pli8zKeUyFKMr85kU7KMyeRsZLbeCnUm70JLl8jmJMjdR8hxjIrpsJu2CMQaG9odKKZdzxfzctphKglFeajGE/BGKREg8Y8U9FsXqhTJgp5rrITQgt5a0GyvvMDeID8yqH4LEAXCzAAAAAElFTkSuQmCC)
    50% 9px no-repeat #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #eee;
  background-size: 12px auto;
  position: fixed;
  right: 25px;
  bottom: 34px;
  color: #999;
  text-align: center;
  padding-top: 20px;
  font-size: 11px;
}

.general-knowledge {
  padding-top: 30px;
  .contents-01 {
    background: url('../img/lore-drug-img-1.jpg') 50% 50% no-repeat #89d4eb;
    background-size: cover;
    padding: 50px 30px !important;
    background-position: 80%;

    .box-guide {
      padding: 15px !important;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      .title-guide {
        font-size: 22px !important;
        color: #008081;
        text-align: center;
      }
    }
  }

  .contents-02 {
    .wrap {
      .title {
        background: #e4f5ff;
        color: #008081;
        margin: 0 -15px;
        padding: 20px 20px 20px;
        font-size: 23px;
        text-align: center;
      }
      .lst-col > div {
        margin-bottom: 30px;
      }
      .lst-col {
        margin: 0 !important;
        padding-top: 30px;
        padding-bottom: 20px;
        text-align: center;

        .col-md-4 {
          padding-right: 15px;
          padding-left: 15px;
          .thumb {
            margin-bottom: 25px;
            img {
              max-width: 50px;
            }
          }
        }

        .title-col {
          color: #008081;
          margin-bottom: 15px;
          font-size: 15px !important;
        }

        p {
          text-align: justify;
        }
        span {
          font-size: 13px !important;
          padding: 4px 12px !important;
          color: #008081;
          border: 1px solid #008081;
          border-radius: 30px;
        }
      }

      .row {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.category-wrap {
  background: url('../img/bg-banner.png') 50% 50% no-repeat #89d4eb;
  background-size: cover;
  height: 346px;
  padding: 98px 40px !important;

  .wrap {
    background: hsla(0, 0%, 100%, 0.8);
    border-radius: 5px;
    height: 155px;

    .box-guide {
      .title-guide {
        font-size: 20px;
        text-align: center;
        border-bottom: 1px solid #bebebf;
        padding: 10px;
      }

      .ctg-lst {
        text-align: center;
        padding-left: 0;

        li {
          padding-bottom: 5px;
        }
        .lst-last {
          padding-bottom: 10px;
        }
      }
    }
  }
}

li {
  list-style: none;
}

.noscroll {
  overflow: hidden;
}

.drug-note {
  .wrap {
    .title-note {
      font-size: 14px;
      background: teal;
      text-align: center;
      color: #fff;
      padding: 12px;
      margin: 0 -15px;
      text-transform: uppercase;
    }
  }
  .post-tabs {
    .nav {
      .nav-item {
        padding-top: 15px;
        color: #555555;

        .icon-1 {
          background-image: url('../icons/tab-icon1.jpg');
        }
        .icon-2 {
          background-image: url('../icons/tab-icon2.jpg');
        }
        .icon-3 {
          background-image: url('../icons/tab-icon3.jpg');
        }
        .icon-4 {
          background-image: url('../icons/llore-page-icon4.png');
        }
        .icon-5 {
          background-image: url('../icons/llore-page-icon5.png');
        }
        .icon-6 {
          background-image: url('../icons/llore-page-icon6.png');
        }
        .icon-7 {
          background-image: url('../icons/llore-page-icon7.png');
        }
        .icon-8 {
          background-image: url('../icons/llore-page-icon8.png');
        }

        .icon-1,
        .icon-2,
        .icon-3,
        .icon-4,
        .icon-5,
        .icon-6,
        .icon-7,
        .icon-8 {
          background-repeat: no-repeat;
          background-size: 40px;
          background-position: top;
          padding: 40px 0 5px 0;
          font-size: 13px;
          color: #555555 !important;
        }

        .icon-1.active {
          background-image: url('../icons/tab-icon1-hover.jpg') !important;
        }
        .icon-2.active {
          background-image: url('../icons/tab-icon2-hover.jpg') !important;
        }
        .icon-3.active {
          background-image: url('../icons/tab-icon3-hover.jpg') !important;
        }
        .icon-4.active {
          background-image: url('../icons/llore-page-icon4-hover.png') !important;
        }
        .icon-5.active {
          background-image: url('../icons/llore-page-icon5-hover.png') !important;
        }
        .icon-6.active {
          background-image: url('../icons/llore-page-icon6-hover.png') !important;
        }
        .icon-7.active {
          background-image: url('../icons/llore-page-icon7-hover.png') !important;
        }
        .icon-8.active {
          background-image: url('../icons/llore-page-icon8-hover.png') !important;
        }

        .nav-link.active {
          border-bottom: 1px solid teal;
          color: teal !important;
        }
        .nav-link {
          display: inline-block;
        }
      }
    }

    .tab-content {
      padding: 10px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.order-method {
  .contents-01 {
    padding: 40px 15px;
    background: url(../img/buy-page-img1.jpg) 50% 100% no-repeat #d0e5ef;
    background-size: cover;
    background-position: 80%;

    .wrap {
      .box-guide {
        padding: 20px !important;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        .title-guide {
          font-size: 22px;
          color: #008081;
          text-align: center;
        }
      }
    }
  }

  .contents-03 {
    padding: 30px 0;
    .wrap {
      .col-left {
        margin-bottom: 20px;
        padding: 0 40px;
        img {
          max-width: 100%;
        }
      }
      .col-right {
        padding: 0;
        text-align: center;

        .title {
          color: #008081;
          font-size: 20px;
        }

        .ck-content {
          padding: 10px;
        }
        .text-center {
          text-align: center;

          .btn-outline-primary {
            color: #008081;
            border: 1px solid #008081;
            border-radius: 30px;
            white-space: normal;
          }
        }
      }
    }
  }

  .contents-05 {
    background: url(../img/omi.png) no-repeat;
    min-height: 350px;
    background-size: cover;
    position: relative;

    .col-left {
      padding: 0 15px;
      position: absolute;
      bottom: 0;
      left: 0;

      .title {
        color: #008081;
        font-size: 20px !important;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .contents-04 {
    padding-top: 0 !important;
    background: url(../img/lienhe.png) no-repeat;
    background-size: 100%;
    position: relative;
    min-height: 350px;

    .col-right {
      padding: 15px 15px;
      position: absolute;
      bottom: 0;
      left: 0;

      .ck-content {
        text-align: justify;
      }

      .title {
        color: #008081;
        font-size: 20px !important;
        text-align: center;
        margin-bottom: 10px;
      }

      .text-center {
        text-align: center;

        .btn-outline-primary:hover {
          color: #ffffff;
          background-color: #008081;
          border-color: #008081 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 128, 129, 1);
        }

        .btn-outline-primary {
          color: #008081;
          border: 1px solid #008081;
          border-radius: 30px;
          white-space: normal;
        }
      }
    }
  }

  .contents-02 {
    background-color: #e3eef4;
    margin-bottom: 25px;

    .col-text {
      padding-top: 30px;

      .title-app {
        font-size: 17px;
        color: #008081;
        text-transform: uppercase;
        padding-bottom: 10px;
        text-align: center;
      }
      .ck-content {
        padding: 10px;
      }
    }

    .col-img {
      width: 100%;
      padding: 0 20%;
      img {
        max-width: 100%;
      }
    }
  }
}

.opacity-1 {
  opacity: 0.2;
}

//ThangHN - Add Reset CSS - Start

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Nunito, san-serif;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

//ThangHN - Add Reset CSS - End
.pt-50 {
  padding-top: 50px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

.btn_custom__form-item {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  height: 50px;
  align-items: center;
  padding: 0 12px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 8px;
}

.btn_custom__input {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  align-items: center;

  input {
    display: flex;
    flex: 1 1;
    height: 50px;
    background-color: #f5f5f5;
    border: none;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    outline: none;
  }
}

.btn_custom__input__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 50px;
}

.btn_custom__password-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.btn_custom__password-container__child {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.btn_custom__password-container__text-left {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #3f3b3b;
}

.btn_custom__password-container__text-right {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: #20abad;
}

.row-input__content__bottom {
  max-width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__btn-left {
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    background: #f15b2e;
    border-radius: 20px;
    padding: 8px 20px;
    border: none;
    transition: 0.5s all;

    &:focus {
      outline: none;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__btn-right {
    font-size: 14px;
    line-height: 19px;
    color: #3f3b3b;
    background: #e0e0e0;
    border-radius: 20px;
    padding: 8px 20px;
    margin-left: 15px;
    border: none;
    transition: 0.5s all;

    &:focus {
      outline: none;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.error-message {
  margin-top: 12px;
  color: #dc3545;
  height: 20px;
}

.result-not-found {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  img {
    width: 200px;
  }
  span {
    font-weight: bold;
  }
}

.icon_chat_fixed {
  position: fixed;
  bottom: 75px;
  right: 15px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Đảm bảo nó ở trên cùng */
  img {
    width: 60px;
  }
}
